<template>
  <div class="container">
    <div class="login">
      <div class="welcome"><span></span>
        <div style="display: flex;flex-direction: row;margin-top: 0.8rem;margin-bottom: 2rem; "> <span>欢迎登录</span>
          <!-- <span style="color:#D33B2E;">十节优选</span><span>！</span> -->
        </div>
      </div>
      <div>
        <van-form @submit="onSubmit">
          <!-- 输入任意文本 -->
          <van-field v-model="nickname" type="tel" placeholder="输入手机号"
            style="border-radius: 2rem; background-color: #F6F6F6;margin-bottom: 1rem;margin-top: 0.5rem;" />
          <van-field type="digit" v-model="smsCode" placeholder="输入验证码" maxlength="6"
            style="border-radius: 2rem; background-color: #F6F6F6;padding: 0.5rem,1rem; margin-top: 0.5rem; ">
            <template #button>
              <div style="display: flex;flex-direction: row; align-items: center;">
                <div style="background-color:#D0D0D0; height: 1.5rem;width: 0.02rem; "></div>
                <div v-if="countdown" style="color:#D59A54;font-size: 1rem;margin-left: 0.5rem;" @click="getCode">{{
          text
        }}</div>
                <div v-else style="color:#D59A54;font-size: 1rem;margin-left: 0.5rem;">{{ count }}秒</div>
              </div>
            </template>
          </van-field>
          <div>
            <div class="foot-btn" native-type="submit" @click="onSubmit">登录</div>
          </div>
          <div style="display: flex;flex-direction: row; margin-top: 1rem;">
            <van-checkbox style="height: 1.2rem; width: 1.5rem;" checked-color="#D59A54" v-model="argeeAll"
              icon-size="1rem" />
            <div style=" font-size: 0.8rem; ">
              <span>我已阅读并同意</span>
              <span style="color:#D59A54;" @click="agree(1)"> 《用户协议》</span>
              <span>及</span>
              <span style="color:#D59A54;" @click="agree(2)"> 《用户隐私政策》</span>
              <span>全部内容</span>
              <div style="margin-top: 0.5rem;">
                <span>未注册的手机号登录成功后将自动注册</span>
              </div>
            </div>
          </div>
        </van-form>
      </div>

    </div>
    <div style="padding-top: 7rem; width: 100%; align-items: center;text-align: center;background-color: white;">
      <p>- 其他登录方式 -</p>
      <van-image style="width: 3.5rem; height: 3.4rem;margin-top: 1.7rem;" :src="require('@/assets/images/wechat.png')"
        @click="init"> </van-image>
      <p style="font-size: 0.71rem;color: #666;margin-top: 0.5rem;">微信登录</p>
    </div>
    <!-- <div
      style="flex: 1;  display: flex;flex-direction: row;width: 100%;align-items: end;justify-content: center; padding-bottom: 1rem;background-color: white;">
      <img src="../../assets/images/record_icon.png" style="height: 1rem;width: 1rem;" />
      <span style="margin-left: 0.2rem; font-size: 0.8rem;">京ICP备2022022909号-2</span>
    </div> -->
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <van-form @submit="onSubmit2">
            <!-- 输入任意文本 -->
            <div style="font-size: 1rem;font-weight: 600;">微信首次登录请绑定手机号</div>

            <van-field v-model="tokenParams.mobile" type="tel" placeholder="输入手机号"
              style="border-radius: 2rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 1rem;" />

            <van-field type="digit" v-model="tokenParams.code" placeholder="输入验证码"
              style="border-radius: 2rem; background-color: #f8f8f8;padding: 0.5rem,1rem; margin-top: 0.5rem;">
              <template #button>
                <div style="display: flex;flex-direction: row; align-items: center;">
                  <div style="background-color:#D0D0D0; height: 1.5rem;width: 0.02rem; "></div>
                  <div v-if="countdown2" style="color:#D59A54;font-size: 1rem;margin-left: 0.5rem;" @click="getCode1">{{
          text2
        }}</div>
                  <div v-else style="color:#D59A54;font-size: 1rem;margin-left: 0.5rem;">{{ count }}秒</div>
                </div>
              </template>
            </van-field>
            <div>
              <div class="foot-btn" native-type="submit" @click="onSubmit2">确认</div>
            </div>
          </van-form>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showPhoneList">
      <div class="wrapper">
        <div class="block">
          <!-- 输入任意文本 -->
          <div style="font-size: 1rem;font-weight: 600;">选择手机号登陆</div>
          <div v-for=" (item, index) in mobileList" class="itemback" @click="loginWechat(item.mobile)">
            {{ item.desenMobile }}
          </div>
          <div v-if="mobileList.length < 3" class="bind-btn" @click="bindPhone">绑定其他手机号</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import HeadChild from "../../components/HeadChild";
// 微信授权登陆地址
const WX_AUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize?';
// 重定向参数-固定写法
const REDIRECT = '#wechat_redirect';
export default {
  name: "Login",
  props: {},
  data() {
    return {
      argeeAll: false,
      nickname: "",
      digit: "",
      number: "",
      count: "",//60秒倒计时
      text: "获取验证码",
      text2: "获取验证码",
      countdown: true,//验证码的状态，默认显示获取验证码
      countdown2: true,
      // 短信验证码
      smsCode: "",
      // 验证码数据
      code: "",
      // 图片验证码
      // verify: null,
      show: false,
      showPhoneList: false,
      disable:true,//防止多次点击
      params: {
        // appid: 'wxbaec9d10a9963029', // 生产 APP ID 
        // appid: 'wx746300fc73d7d2ba', //测试
        appid: process.env.VUE_APP_WX_APPID, //测试
        redirect_uri: `${encodeURI(process.env.VUE_APP_WX_BACK + 'Login')}`,
        response_type: 'code', // 固定写法
        scope: 'snsapi_userinfo', // snsapi_base 静默授权获取 open id ；snsapi_userinfo 需要用户授权，获取详细信息
        // state:'STATE', // a-zA-Z0-9的参数值，最多128字节
      },
      tokenParams: {
        code: '',
        openId: '',
        mobile: '',
      },

      mobileList: [],
      code: '', // 微信返回 code
      isLogin: false
    };
  },
  created() {
    // 获取地址参数
    const params = new URLSearchParams(location.search);
    this.code = params.get('code');
    if (this.code) {
      this.initToken()
    }
  },
  components: { HeadChild },
  methods: {
    agree(val) {
      this.$router.push({
        path: "/Agreement",
        query: {
          type: val
        }
      });
    },
    bindPhone() {
      this.show = true
      this.showPhoneList = false
    },
    // 返回
    back() {
      this.$router.back();
    },
    // 表单校验是否通过 通过才触发
    onSubmit() {
  
      let result = this.validatePhoneNumber(this.nickname)
      if (!result) {
        this.$Toast('请输入正确格式手机号');
        return
      }
      if (this.smsCode == '') {
        this.$Toast('验证码不能为空');
        return
      }
      if (!this.argeeAll) {
        this.$Toast('请阅读并同意用户协议');
        return;
      }
      if(!this.disable){
        return
      }
      this.disable =false
      this.$api
        .loginSms(
          this.nickname,
          this.smsCode
        )
        .then((res) => {
          if (res.code == 1) {
            this.disable =true
            this.back()
            this.$Toast(res.msg);
            return
          }
          if (res.user_info.openId) {
            localStorage.setItem("openId", res.user_info.openId);
          } else {
            localStorage.setItem("openId", '');
          }
          this.isLogin = true
          // // 本地储存用户名
          this.$store.commit("setNickname", res.user_info.realName);
          localStorage.setItem("nickname", res.user_info.realName);
          localStorage.setItem("access_token", res.access_token);
          localStorage.setItem("userInfo", JSON.stringify(res.user_info));
          localStorage.setItem('loginphone', this.nickname);
          this.back()
        })
        .catch((err) => {
          this.disable =true
          this.$Toast(err.msg);
        });
    },
    validatePhoneNumber(phoneNumber) {
      if (phoneNumber.length !== 11) {
        return false;
      }
      var reg = /^1\d{10}$/;
      return reg.test(phoneNumber);
    },
    onSubmit2() {
      let result = this.validatePhoneNumber(this.tokenParams.mobile)
      if (!result) {
        this.$Toast('请输入正确格式手机号');
        return
      }
      if (this.tokenParams.code == '') {
        this.$Toast('验证码不能为空');
        return
      }
      this.$api
        .bindUserByOpenId(this.tokenParams)
        .then((res) => {
          if (res.code == 0) {
            this.loginWechat(this.tokenParams.mobile)
          } else {
            this.$Toast(res.msg);
          }
        })
        .catch((err) => {
              
          this.$Toast(err);
        });
    },
    getCode1() {
      let result = this.validatePhoneNumber(this.tokenParams.mobile)
      if (!result) {
        this.$Toast('请输入正确格式手机号');
        return
      }
      this.$api
        .sendSms(this.tokenParams.mobile)
        .then((res) => {
          if (res.code == 1) {
            this.back()
            this.$Toast(res.msg);
            return
          }
          this.changeButton1()

        })
        .catch((err) => {
        });
    },
    getCode() {
      let result = this.validatePhoneNumber(this.nickname)
      if (!result) {
        this.$Toast('请输入正确格式手机号');
        return
      }
      this.$api
        .sendSms(this.nickname)
        .then((res) => {
          if (res.code == 1) {
            this.back()
            this.$Toast(res.msg);
            return
          }
          this.changeButton()
        })
        .catch((err) => {
        });
    },
    changeButton1() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.countdown2 = false;
        this.text2 = "重新发送"
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.countdown2 = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    changeButton() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.countdown = false;
        this.text = "重新发送"
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.countdown = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    init() {
      if (!this.argeeAll) {
        this.$Toast('请阅读并同意用户协议');
        return;
      }
      // 访问地址
      const access_url = WX_AUTH_URL + `${new URLSearchParams(this.params)}` + REDIRECT;
      // 这些需要判断没有 code 情况拉起授权登陆，有就结束放在重复拉起授权登陆
      if (!this.code) {
        location.href = access_url;
      }
    },
    initToken() {
      this.$api
        .codeCallback(this.code)
        .then((res) => {
          if (res.code == 0) {
            this.tokenParams.openId = res.data.openid
            localStorage.setItem("openId", res.data.openid);
            // if (res.data.bindFlag == '0') {  // list.length   >= 5
            //   this.getPhoneList()

            // 
            this.getPhoneList()

          } else {
            this.back()
            this.$Toast(res.msg);
          }

        })
        .catch((err) => {
          this.$Toast(err);
        });
    },
    showDialog() {
      this.$Dialog
        .confirm({
          title: "绑定手机号",
          message: "为了提升您的登陆体验，绑定手机号码后即可通过微信快捷登录。",
        })
        .then(() => {
          this.show = true
        })
        .catch(() => {
          this.back()
        });
    },
    getPhoneList() {
      this.$api
        .queryUserMobiles(this.tokenParams.openId)
        .then((res) => {
          if (res.code == 1) {
            this.back()
            this.$Toast(res.msg);
            return
          }
          this.mobileList = res.data
          if (this.mobileList.length > 0) {

            this.showPhoneList = true

          } else {
            this.showDialog()
          }

        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    loginWechat(mobile) {
      if (this.showPhoneList) {
        this.showPhoneList = false
      }
      this.$api
        .loginWechat(
          this.tokenParams.openId,
          mobile
        )
        .then((res) => {
          if (res.code === 1) {
            this.back()
            this.$Toast(res.msg);
            return
          }
          this.isLogin = true
          // // 本地储存用户名
          this.$store.commit("setNickname", res.user_info.realName);
          localStorage.setItem("nickname", res.user_info.realName);
          localStorage.setItem("access_token", res.access_token);
          localStorage.setItem("userInfo", JSON.stringify(res.user_info));
          localStorage.setItem('loginphone', mobile);
          window.history.back();
        })
        .catch((err) => {
          this.$Toast(err.msg);
        });
    },
    goBack() {
      let access_token = localStorage.getItem('access_token')
      if (access_token) {
        window.history.back();
      } else {
        this.closeWXWindow()
      }
    },
    /** 关闭微信浏览器 */
    closeWXWindow() {
      // 微信浏览器里有一个 WeixinJSBridge 对象，就像普通浏览器里必有 window 对象一样，无需额外引入
      // 不过保险起见，还是判断一下是否为空
      if (WeixinJSBridge?.invoke) {
        console.log('微信浏览器')
        WeixinJSBridge.invoke('closeWindow', {}, () => {
        });
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    console.log('path', to.path)
    if (to.path === '/Agreement') {
      from.meta.keepAlive = true
    } else {
      from.meta.keepAlive = false
    }
    next()
  },
  mounted() {

    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    let access_token = localStorage.getItem('access_token')
    if (access_token) {
      window.history.back();
    }
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          window.location.reload()
        }
      };
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  computed: {},
  watch: {},

};
</script>

<style lang='scss' scoped>
#app {
  height: 100%;
  background-color: white;
}

.container {
  height: 100vh;
  width: 100%;
  font-size: 14px;
  background-color: white;
  background-image: url("../../assets/images/login_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
}

.van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem 1.1rem;
  overflow: hidden;
  color: #323233;
  font-size: 1.1rem;
  line-height: 6.4vw;
  background-color: #fff;
}

.login {
  padding-top: 3rem;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 0.5rem;
}

.foot-btn {
  width: 100%;
  margin-top: 2.85rem;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  line-height: normal; //安卓字体偏上
  text-align: center;
  border-radius: 1.5rem;
  color: #fff;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  font-size: 1.1rem;
}

.bind-btn {
  width: 100%;
  margin-top: 1.5rem;
  padding: 0.8rem 0;
  display: flex;
  justify-content: center;
  line-height: normal; //安卓字体偏上
  text-align: center;
  border-radius: 1.5rem;
  color: #fff;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  font-size: 1.1rem;
}

.itemback {
  border-radius: 0.2rem;
  border: 1px solid #ddd;
  padding: 0.8rem;
  margin: 0.5rem 0.5rem 0 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 20rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: #fff;
  padding-bottom: 2rem;
}

.welcome {
  font-size: 1.3rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}
</style>